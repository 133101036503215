<template>
  <div class="product-pages-category" ref="productPagesCategory">
    <div class="pl-0 pr-0">
      <CategoryFiltersComponent
        :sorting="sorting"
        @change-sorting="changeSorting"
        :select-options="selectOptions"
        :prods-per-page="prodsPerPage"
        @change-per-page="changePerPage"
        :products-count="totalProducts"
      />

      <b-row v-if="isLoading" class="pro-loader">
        <div class="reverse-spinner"></div>
      </b-row>
      <b-row v-else-if="totalProducts === 0">
        <b-col>
          <p class="text-center">{{ $t("no_products") }}</p>
        </b-col>
      </b-row>
      <b-row
        class="category-products-grid mx-0"
        id="category-products-grid"
        v-else
      >
        <b-col
          v-for="product in products"
          v-bind:key="product.id"
          class="category-products-item p-0"
        >
          <ProductCard :product="product" />
        </b-col>
      </b-row>

      <div
        v-if="totalProducts > 0"
        class="pb-30 pagination-header category-right-top-list-header mt-0"
      >
        <div class="category-list-left">
          {{ totalProducts }} {{ $t("result") }} | &nbsp; {{ $t("sort_by") }}
          <span class="count-icon">
            <select
              id="collection"
              class="filter-list bold"
              :value="sorting"
              @change="changeSorting"
            >
              <option
                v-for="option in selectOptions"
                v-bind:key="option.value"
                v-bind:value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </span>
        </div>
        <div class="category-list-right pagination-wrap">
          <b-pagination
            :value="currentPage"
            @change="changePage($event)"
            :total-rows="totalProducts"
            :per-page="prodsPerPage"
            first-number
            last-number
            :prev-class="{ 'd-none': currentPage == 1 }"
                            :next-class="{
                  'd-none': currentPage == Math.ceil(totalProducts / prodsPerPage),
                }"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@config";
import ProductCard from "@/esf_utrecht_bankxl/core/components/products/category-product/ProductCard";
import CategoryFiltersComponent from "@/esf_utrecht_bankxl/core/components/products/category-product/CategoryFiltersComponent";

export default {
  name: "FilterComponent",
  components: {
    ProductCard,
    CategoryFiltersComponent,
  },
  data() {
    return {
      isActive: false,
    };
  },
  mounted() {
    const { page } = this.$route.query;
    const path = "/" + this.category.url_path + "/";
    const query = { ...this.$route.query };

    if (page && this.totalPages < parseInt(page)) {
      delete query["page"];
    }
    // Remove Page-size=15

    // const pageSize = config.products?.perPage;
    
    // if (typeof query["page-size"] === 'undefined') {
    //     query["page-size"] = pageSize;
    // }
    
    // if (query["page-size"] % 5 != 0) {
    //   query["page-size"] = Math.round(query["page-size"] / 5) * 5;
    // }
    
    // const perPage = this.$store.getters["category/getProductsPerPage"];
    // if (perPage != pageSize) query["page-size"] = perPage;

    this.$router.replace({ path, query });
    this.updateBreadcrumbs();
  },

  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    isLoading() {
      return this.$store.getters["category/getLoadProducts"];
    },
    showProducts() {
      return this.$store.getters["category/getShowProducts"];
    },
    selectOptions() {
      const sortOpt = [];
      Object.keys(config.sortingOptions).forEach((element) => {
        const option = {
          value: element,
          text: this.$t(`sortingOptions.${element}`),
        };
        sortOpt.push(option);
      });
      return sortOpt;
    },
    sorting() {
      return this.$store.getters["category/getSorting"];
    },
    products() {
      if (this.showProducts.length == 0) {
        return [];
      }
      return this.showProducts;
    },
    currentPage() {
      return +this.$store.getters["category/getCurrentPage"];
    },
    prodsPerPage() {
      return +this.$store.getters["category/getProductsPerPage"];
    },
    totalProducts() {
      return +this.$store.getters["category/getTotalProducts"];
    },
  },
  methods: {
    changeSorting(event) {
      const sortVal = event.target.value;
      this.$store.commit("category/setSorting", sortVal);

      const { path } = this.$route;
      let query = Object.assign({}, this.$route.query);
      let queryString = window.location.search;
      let searchParams = new URLSearchParams(queryString);

      searchParams.forEach((value, key) => {
        query[key] = value;
      });

      const { sortDefault } = config;
      if (config.sortingOptions[sortVal] && sortVal !== sortDefault) {
        query["sort"] = sortVal;
      } else {
        delete query["sort"];
      }
      delete query["page"];

      window.history.replaceState(
        history.state,
        null,
        path +
          "?" +
          Object.keys(query)
            .map((key) => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(query[key])
              );
            })
            .join("&")
      );
    },
    changePage(pageNo) {
      // localStorage.setItem("v-remember-scroll-position", window.scrollY);
      // localStorage.setItem("v-remember-scroll-page", window.location.pathname);

      this.$store.commit("category/setCurrentPage", pageNo);

      const { path } = this.$route;
      let query = Object.assign({}, this.$route.query);
      let queryString = window.location.search;
      let searchParams = new URLSearchParams(queryString);

      searchParams.forEach((value, key) => {
        query[key] = value;
      });
      if (pageNo != 1) {
        query["page"] = pageNo;
      } else {
        delete query["page"];
      }

      window.history.replaceState(
        history.state,
        null,
        path +
          "?" +
          Object.keys(query)
            .map((key) => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(query[key])
              );
            })
            .join("&")
      );

      // Scroll back to Top
      const categorySliderElement = this.$refs.productPagesCategory;

      if (categorySliderElement) {
        const elementTop = categorySliderElement.getBoundingClientRect().top + window.scrollY;

        const offset = 225; 

        window.scrollTo({
          top: elementTop - offset,
          behavior: 'smooth'
        });
      } else {
        console.error("Category slider element not found");
      }
    },

    changePerPage(event) {
      const perPage = +event.target.value;
      const { path } = this.$route;
      let query = Object.assign({}, this.$route.query);
      let queryString = window.location.search;
      let searchParams = new URLSearchParams(queryString);

      searchParams.forEach((value, key) => {
        query[key] = value;
      });
      // const pageSize = +config.products?.perPage;

      // if (perPage != pageSize) {
        query["page-size"] = perPage;
      // } else {
      //   delete query["page-size"];
      //   delete query["page"];
      // }
      window.history.replaceState(
        history.state,
        null,
        path +
          "?" +
          Object.keys(query)
            .map((key) => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(query[key])
              );
            })
            .join("&")
      );
      this.$store.commit("category/setProductsPerPage", perPage);
    },
    goToPage(page) {
      // localStorage.setItem("v-remember-scroll-position", window.scrollY);
      // localStorage.setItem("v-remember-scroll-page", window.location.pathname);
      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (page != 1) query["page"] = page;
      else delete query["page"];

      this.$refs.productPagesCategory?.scrollIntoView({ behavior: 'smooth' });
      return { path, query };
    },
    updateBreadcrumbs() {
      if (this.category) {
        const current = this.category.name;
        let breadcrumb = { current, routes: [] };

        if (this.category.breadcrumbs) {
          const { breadcrumbs } = this.category;
          if (breadcrumbs != null) {
            breadcrumbs.sort((a, b) => {
              if (a.category_level < b.category_level) return -1;
              if (a.category_level > b.category_level) return 1;
              return 0;
            });

            let path = "";
            breadcrumbs.forEach((element) => {
              if (path.length > 0) {
                path = path + "/";
              }
              path = path + element.category_url_key + "/";
              breadcrumb.routes.push({
                name: element.category_name,
                route_link: "/" + path,
              });
            });
          }
        }
        this.$store.commit("breadcrumbs/set", breadcrumb);
      }
    },
  },

  // Below watcher is conflicting the scroll condition so have commented it
  // To check scroll condition go to CategoryComponent.vue -watcher
  // watch: {
  //   isLoading() {
  //     if (this.isLoading == false) {
  //       const prevPage = localStorage.getItem("v-remember-scroll-page");
  //       const scrollPosition = localStorage.getItem(
  //         "v-remember-scroll-position"
  //       );
  //       if (
  //         scrollPosition &&
  //         scrollPosition.length > 0 &&
  //         prevPage === window.location.pathname
  //       ) {
  //         window.scrollTo(0, scrollPosition);
  //       }
  //     }
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.pro-loader {
  text-align: center;
  padding: 100px 0px 120px 0px;
  .reverse-spinner {
    position: relative;
    height: 100px;
    width: 100px;
    margin: 0 auto;
    border: 4px solid transparent;
    border-top-color: $primary-gray;
    border-left-color: $primary-gray;
    border-radius: 50%;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  @media screen and (max-width: 560px) {
    padding: 50px 0px;
    .reverse-spinner {
      height: 80px;
      width: 80px;
    }
  }

  .reverse-spinner::before {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    content: "";
    border: 4px solid transparent;
    border-top-color: $primary-gray;
    border-left-color: $primary-gray;
    opacity: 0.7;
    border-radius: 50%;
    -webkit-animation: spinBack 1s linear infinite;
    animation: spinBack 1s linear infinite;
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinBack {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}
</style>
