<template>
  <div
    class="category_slider"
    v-if="categorySliders.slides"
    :class="borderClass"
  >
    <h3>
      {{ $t("read_more_about_slider") }}
    </h3>
    <VueSlickCarousel
      v-if="categorySliders.slides.length"
      :arrows="true"
      :dots="false"
      :slidesToShow="5"
      :slidesToScroll="1"
      :autoplay="false"
      :center="false"
      :responsive="[
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ]"
    >
      <div
        class="cms-block-content"
        v-for="(slide, index) in categorySliders.slides"
        :key="index"
      >
        <div>
          <b-card class="home-category-card">
            <router-link :to="slide.link">
              <div class="card-img-top-box">
                <b-img
                  class="card-img-top"
                  :src="slide.media.url"
                  :alt="slide.title"
                />
              </div>
              <div class="card-body-custom text-center">
                <div v-html="slide.title" class="card-title"></div>
              </div>
            </router-link>
          </b-card>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import { isMobile, isTablet } from "mobile-device-detect";

export default {
  name: "CategorySlider",
  components: { VueSlickCarousel },
  data() {
    return {
      noOfSlidePerPage: 5,
      categorySliders: {
        slides: [],
      },
    };
  },
  computed: {},
  props: {
    category: { type: Number, required: true },
    borderClass: { type: String, required: false },
  },
  methods: {
    setNoOfSlidePerPage() {
      if (isMobile && !isTablet) {
        this.noOfSlidePerPage = 2;
      } else if (isTablet) {
        this.noOfSlidePerPage = 3;
      } else {
        this.noOfSlidePerPage = 5;
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("categorySlider/loadCategorySliders", {
      id: this.category,
    });
    var sliders = this.$store.getters["categorySlider/getCategorySliders"];
    if (sliders && sliders.length) {
      this.categorySliders = sliders[0];
    }
    this.setNoOfSlidePerPage();
  },
  watch: {
    async category() {
      await this.$store.dispatch("categorySlider/loadCategorySliders", {
        id: this.category,
      });
      var sliders = this.$store.getters["categorySlider/getCategorySliders"];
      if (sliders && sliders.length) {
        this.categorySliders = sliders[0];
      }
    },
  },
};
</script>
