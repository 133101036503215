<template>
  <div class="category">
    <h3 class="category-heading">{{ $t("categories") }}</h3>
    <ul class="left-category-item-list">
      <li v-for="(list, index) in category.children" :key="index">
        <router-link :to="`/` + list.url_path + '/'">{{
          list.name
        }}</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "SidebarCategoryComponent",
  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
  },
};
</script>
