<template>
  <v-runtime-template
    :class="['cms-block-content']"
    v-if="content"
    :template="`<div>` + content + `</div>`"
  ></v-runtime-template>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";

export default {
  name: "CategoryBlockSimple",
  components: { VRuntimeTemplate },
  props: {
    identifier: {
      type: String,
      default: null,
      required: false,
    },
    content: {
      type: String,
      default: null,
      required: false,
    },
  },
  async mounted() {
    if (!this.content) {
      await this.fetchCmsBlock();
    }
  },
  methods: {
    async fetchCmsBlock() {
      let queryKey = "";
      let queryValue = "";
      queryKey = "identifier";
      queryValue = this.identifier;
      if (queryKey && queryValue) {
        await this.$store.dispatch("cmsBlock/single", {
          key: queryKey,
          value: queryValue,
        });
        this.getCmsData = this.$store.getters[
          `cmsBlock/getCmsBlockByIdentifier`
        ](this.identifier);
      }
    },
  },
  computed: {},
  data() {
    return {
      getCmsData: {},
    };
  },
};
</script>
<style lang="scss">
.cms-block-content {
  p {
    margin-bottom: 0px;
  }
  strong,
  b {
    font-weight: 500;
  }
}
</style>
