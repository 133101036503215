<template>
  <div class="categoryPage top-padding">
    <CategoryComponent />
  </div>
</template>

<script>
// import config from "@config";
import CategoryComponent from "@/esf_utrecht_bankxl/core/components/products/category-product/CategoryComponent.vue";
import config from "@config";

export default {
  name: "CategoryPage",
  components: {
    CategoryComponent,
  },
  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
  },
  watch: {
    // $route(to, from) {
    //   if (to.path !== from.path ) {
    //     if (
    //       this.category.display_mode === "PRODUCTS" ||
    //       this.category.display_mode === "PRODUCTS_AND_PAGE"
    //     ) {
    //       const query = {};
    //       const pageSize = config.products?.perPage;
    //       const perPage = this.$store.getters["category/getProductsPerPage"];
    //       if (perPage != pageSize) query["page-size"] = perPage;
    //       this.$router.push({ path: to.path, query: query });
    //     }
    //   }
    // },
  },
  metaInfo() {
    return {
      title: this.category.name,
      link: [
        {
          rel: "canonical",
          href: this.category.canonical_url
            ? config.app_url + this.category.canonical_url
            : config.app_url +
              (/\/$/.test(this.$route.fullPath)
                ? this.$route.fullPath
                : this.$route.fullPath.replace(/^\//, "") + "/"),
        },
      ],
    };
  },
};
</script>
