<template>
  <div class="category-filter">
    <div class="desktop-show category-right-top-list-header">
      <p class="category-list-left">
        {{ productsCount }} {{ $t("result") }} | &nbsp; {{ $t("sort_by") }}
        <span class="count-icon">
          <select
            id="collection"
            class="filter-list bold"
            :value="sorting"
            @change="$emit('change-sorting', $event)"
          >
            <option
              v-for="option in selectOptions"
              v-bind:key="option.value"
              v-bind:value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </span>
      </p>
      <p class="category-list-right">
        <span class=""> {{ categoryCollection2 }}</span> &nbsp;
        <span class="count-icon">
          <select
            id="collection2"
            class="filter-list bold"
            @change="$emit('change-per-page', $event)"
            :value="prodsPerPage"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
        </span>
      </p>
    </div>
    <div class="tab-mob-show">
      <div class="pt-20 filter-box category-right-top-list-header">
        <p class="category-list-left d-flex align-items-center">
          <span class="filter-txt-span">
            {{ productsCount }} {{ $t("result") }} | &nbsp;
            {{ $t("sort_by") }} </span
          >&nbsp;
          <span class="">
            <select
              id="collection3"
              class="filter-list-mob bold"
              :value="sorting"
              @change="$emit('change-sorting', $event)"
            >
              <option
                v-for="option in selectOptions"
                v-bind:key="option.value"
                v-bind:value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </span>
        </p>
        <p class="category-list-righ d-flex align-items-center">
          <span class="filter-txt-span"> {{ categoryCollection2 }}</span
          >&nbsp;
          <span>
            <select
              id="collection2"
              class="filter-list-mob"
              @change="$emit('change-per-page', $event)"
              :value="prodsPerPage"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CategoryFiltersComponent",
  emits: ["change-sorting", "change-per-page"],
  props: {
    productsCount: Number,
    sorting: String,
    selectOptions: Array,
    prodsPerPage: Number,
  },
  mounted() {},
  data() {
    return {
      categoryCollection2: "Aantal resultaten per pagina tonen",
    };
  },
};
</script>